
body {
  line-height: 26px;
  font-family: "Open Sans", sans-serif;
  color: #444;
  font-size: 1rem;

  #logo {
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
  }

  section {
    padding: 60px 1rem 30px;

    @media screen and (min-width: 1024px) {
        padding-top: 120px;
    }

    h2 {
      margin-bottom: 20px;
      color: #0c2e8a;
      font-weight: 700;
      font-size: 36px;
      font-family: "Montserrat", sans-serif;
      line-height: 3rem;
    }
  }

  #hero {
    background-image: url('../public/hero/hero-bg.jpg');
    background-position: center;
    background-size: cover;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.9;
    }

    > * {
      position: relative;
    }

    #headline {
      font-family: "Montserrat", sans-serif;
    }
  }

  .service-card {
    padding: 40px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 15px rgba(73, 78, 92, 0.1);
    height: 370px;
    text-align: center;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    div {
      padding-bottom: 15px;
      font-size: 64px;
    }

    h4 {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 15px;
      font-family: "Montserrat", sans-serif;

      @media screen and (min-width: 1024px) {
        margin: 40px auto;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }

  #contact-cards {
    &>div {
      text-align: center;
      margin-bottom: 20px;

      h4 {
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: 700;
        text-transform: uppercase;
        color: #50d8af;
      }

      p {
        color: #000;
      }
    }
  }
}